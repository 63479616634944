import { connect } from "react-redux";
import { PRODUCT_EXPERIENCES_WITH_HOTEL } from "app/constants";

import TravelDates from "app/pages/.shared/TravelDates/TravelDates";

const mapStateToProps = (state, ownProps) => {
	const currentMerchCode = state.merchandising?.current?.code;
	let booking = state.booking;
	const isWithHotel = PRODUCT_EXPERIENCES_WITH_HOTEL.includes(
		state?.ficheProduit?.productExperience
	);

	if (!ownProps.isHolidaySummary) {
		if (currentMerchCode) {
			booking = state.booking.smartDPMerch;
		}
	}

	return {
		booking,
		isWithHotel,
	};
};

export default connect(mapStateToProps)(TravelDates);
