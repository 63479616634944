import { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FormattedDate, FormattedMessage } from "react-intl";
import { differenceInDays } from "date-fns";
import last from "lodash/last";
import { OFFER_TYPES } from "app/constants";
import LoaderBar from "app/pages/.shared/LoaderBar/LoaderBar";

const TravelDates = ({ booking = {}, isWithHotel = false }) => {
	const duration = booking?.duration?.value;
	const departureDate = booking?.departureDate && new Date(booking.departureDate);
	const [bookingDays, setBookingDays] = useState(null);

	let inboundLandingDate = booking.endDate;

	if (booking.offer && booking.offer.type === OFFER_TYPES.FLIGHT_WITH_ACCOMMODATION) {
		inboundLandingDate =
			booking.flight &&
			booking.flight.inbound &&
			booking.flight.inbound.legs &&
			booking.flight.inbound.legs.length > 0
				? last(booking.flight.inbound.legs).to.landingDate
				: undefined;
	}
	const endDate = inboundLandingDate && new Date(inboundLandingDate);

	useEffect(() => {
		if (endDate) {
			setBookingDays(differenceInDays(endDate, departureDate) + 1);
		}
	}, [endDate, departureDate]);

	return departureDate > 0 ? (
		<div className="travel-dates">
			<div className="travel-dates__dates" data-testid="detail-travel-dates">
				{departureDate && inboundLandingDate && bookingDays && duration ? (
					<>
						<FormattedDate
							timeZone="UTC"
							value={departureDate}
							month="short"
							day="numeric"
							weekday="short"
							year="numeric"
						/>
						{" - "}
						<FormattedDate
							timeZone="UTC"
							value={inboundLandingDate}
							month="short"
							day="numeric"
							weekday="short"
							year="numeric"
						/>
					</>
				) : (
					<LoaderBar height={10} />
				)}
			</div>
			{duration > 0 && (
				<div className="travel-dates__duration" data-testid="detail-travel-duration">
					{departureDate && inboundLandingDate && bookingDays && duration ? (
						<FormattedMessage
							id={
								isWithHotel
									? "sdp.search.summary.duration"
									: "sdp.search.summary.duration.without.hotel"
							}
							values={{ days: bookingDays, nights: duration }}
						/>
					) : (
						<LoaderBar height={10} />
					)}
				</div>
			)}
		</div>
	) : (
		<div className="travel-dates travel-dates--error">
			<FormattedMessage id="sdp.search.departure.date.input.label" />
		</div>
	);
};

TravelDates.propTypes = {
	booking: PropTypes.object,
	isWithHotel: PropTypes.bool,
};

export default memo(TravelDates);
