import PropTypes from "prop-types";
import withRouter from "app/utils/hocs/withRouter";
import { messagePropType } from "app/utils/propTypes";
import { connect } from "react-redux";
import ReasonToBookBlock from "app/pages/.shared/ReasonsToBook/ReasonToBookBlock/ReasonToBookBlock";
import { getComputedReasonToBookList, shouldShowReasonToBook } from "app/reducers/partnerSelector";
import { useLocation } from "react-router-dom";

const ReasonToBookBlockContainer = props => {
	const { pathname } = useLocation();

	// use key={pathname} to force ReasonToBookBlock to rerender to reset carousel index to 0
	return props.shouldShowReasonToBook ? <ReasonToBookBlock {...props} key={pathname} /> : false;
};

ReasonToBookBlockContainer.propTypes = {
	title: messagePropType,
	reasonToBookList: PropTypes.array,
	titleHeadingLevel: PropTypes.number,
	shouldShowReasonToBook: PropTypes.bool,
};

const mapStateToProps = (state, ownProps) => {
	const { location } = ownProps;
	const { signupTitle, defaultTitle, items = [] } = getComputedReasonToBookList(state);
	const isSignupPage =
		location.pathname.includes("/landing-page") || location.pathname.includes("/home");
	return {
		title: isSignupPage ? signupTitle || defaultTitle : defaultTitle,
		reasonToBookList: items,
		shouldShowReasonToBook: shouldShowReasonToBook(state),
	};
};

export default withRouter(connect(mapStateToProps)(ReasonToBookBlockContainer));
