import PropTypes from "prop-types";
import ReasonToBookCarousel from "app/pages/.shared/ReasonsToBook/ReasonToBookCarousel/ReasonToBookCarousel";
import { messagePropType } from "app/utils/propTypes";
import { memo } from "react";
import "./ReasonToBookBlock.scss";

const ReasonToBookBlock = ({ reasonToBookList = [], title = [], titleHeadingLevel = "3" }) => {
	// h2 sur home sdp et h3 ailleurs
	const HeadingTag = `h${titleHeadingLevel}`;

	return (
		reasonToBookList.length > 0 && (
			<div className="reason-to-book-block" data-testid="reason-to-book-block">
				{title && <HeadingTag className="reason-to-book-block__title">{title}</HeadingTag>}
				<ReasonToBookCarousel reasonToBookList={reasonToBookList} />
			</div>
		)
	);
};

ReasonToBookBlock.propTypes = {
	title: messagePropType,
	reasonToBookList: PropTypes.array,
	titleHeadingLevel: PropTypes.number,
};

export default memo(ReasonToBookBlock);
