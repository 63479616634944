import { memo } from "react";
import PropTypes from "prop-types";
import Paragraphs from "app/pages/.shared/Paragraphs";
import "./ReasonToBook.scss";

const ReasonToBook = ({ title, description, picto }) => {
	return (
		<div className="reason-to-book">
			{picto && (
				<div className="reason-to-book__picto">
					<i
						style={{
							maskImage: `url(${picto}`,
							WebkitMaskImage: `url(${picto}`,
						}}
					/>
				</div>
			)}
			<div>
				{title && <div className="reason-to-book__title">{title}</div>}
				{description && (
					<div className="reason-to-book__description">
						<Paragraphs paragraphs={description} enableHTMLFormatting />
					</div>
				)}
			</div>
		</div>
	);
};

ReasonToBook.propTypes = {
	title: PropTypes.string,
	description: PropTypes.string,
	picto: PropTypes.string,
};

export default memo(ReasonToBook);
